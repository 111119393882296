<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-card outlined>
          <v-card-title class="grey lighten-4">
            Contact Information
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  depressed
                  color="blue lighten-4 blue--text"
                  v-on="on"
                  @click="openContactDetails"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-simple-table>
            <tbody>
              <tr>
                <th>Mobile Phone Number</th>
                <td>
                  {{
                    teamMember.contact_details &&
                    teamMember.contact_details.mobile_phone
                      ? teamMember.contact_details.mobile_phone
                      : "N/A"
                  }}
                </td>
              </tr>
              <tr>
                <th>Home Phone Number</th>
                <td>
                  {{
                    teamMember.contact_details &&
                    teamMember.contact_details.home_phone
                      ? teamMember.contact_details.home_phone
                      : "N/A"
                  }}
                </td>
              </tr>
              <tr>
                <th>Address</th>
                <td>
                  {{
                    teamMember.contact_details &&
                    teamMember.contact_details.address
                      ? teamMember.contact_details.address
                      : "N/A"
                  }}
                </td>
              </tr>
              <tr>
                <th>Secondary Email</th>
                <td>
                  {{
                    teamMember.contact_details &&
                    teamMember.contact_details.secondary_email
                      ? teamMember.contact_details.secondary_email
                      : "N/A"
                  }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined>
          <v-card-title class="grey lighten-4">
            Emergency Contacts
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  depressed
                  color="blue lighten-4 blue--text"
                  v-on="on"
                  @click="openEmergencyContacts"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-card outlined>
                  <v-card-subtitle class="grey lighten-5">
                    Emergency Contact 1
                  </v-card-subtitle>
                  <v-divider></v-divider>
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <td>
                          {{
                            teamMember.contact_details &&
                            teamMember.contact_details.emergency_1_name
                              ? teamMember.contact_details.emergency_1_name
                              : "N/A"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th>Mobile Phone Number</th>
                        <td>
                          {{
                            teamMember.contact_details &&
                            teamMember.contact_details.emergency_1_mobile_phone
                              ? teamMember.contact_details
                                  .emergency_1_mobile_phone
                              : "N/A"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th>Home Phone Number</th>
                        <td>
                          {{
                            teamMember.contact_details &&
                            teamMember.contact_details.emergency_1_home_phone
                              ? teamMember.contact_details
                                  .emergency_1_home_phone
                              : "N/A"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th>Address</th>
                        <td>
                          {{
                            teamMember.contact_details &&
                            teamMember.contact_details.emergency_1_address
                              ? teamMember.contact_details.emergency_1_address
                              : "N/A"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card outlined>
                  <v-card-subtitle class="grey lighten-5">
                    Emergency Contact 2
                  </v-card-subtitle>
                  <v-divider></v-divider>
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <td>
                          {{
                            teamMember.contact_details &&
                            teamMember.contact_details.emergency_2_name
                              ? teamMember.contact_details.emergency_2_name
                              : "N/A"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th>Mobile Phone Number</th>
                        <td>
                          {{
                            teamMember.contact_details &&
                            teamMember.contact_details.emergency_2_mobile_phone
                              ? teamMember.contact_details
                                  .emergency_2_mobile_phone
                              : "N/A"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th>Home Phone Number</th>
                        <td>
                          {{
                            teamMember.contact_details &&
                            teamMember.contact_details.emergency_2_home_phone
                              ? teamMember.contact_details
                                  .emergency_2_home_phone
                              : "N/A"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th>Address</th>
                        <td>
                          {{
                            teamMember.contact_details &&
                            teamMember.contact_details.emergency_2_address
                              ? teamMember.contact_details.emergency_2_address
                              : "N/A"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="contactDetailsForm.dialog" max-width="600px">
      <v-card>
        <v-form @submit.prevent="saveContactInformation" method="post">
          <v-card-title>
            <span class="headline">Edit Contact Information</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              label="Mobile Phone Number *"
              type="text"
              v-model="contactDetailsForm.fields.mobile_phone"
              outlined
              :error="contactDetailsForm.errors.hasOwnProperty('mobile_phone')"
              :error-messages="contactDetailsForm.errors['mobile_phone']"
            ></v-text-field>
            <v-text-field
              label="Home Phone Number"
              type="text"
              v-model="contactDetailsForm.fields.home_phone"
              outlined
              :error="contactDetailsForm.errors.hasOwnProperty('home_phone')"
              :error-messages="contactDetailsForm.errors['home_phone']"
            ></v-text-field>
            <v-textarea
              label="Address"
              v-model="contactDetailsForm.fields.address"
              outlined
              rows="5"
              :error="contactDetailsForm.errors.hasOwnProperty('address')"
              :error-messages="contactDetailsForm.errors['address']"
            ></v-textarea>
            <v-text-field
              label="Secondary Email"
              type="email"
              v-model="contactDetailsForm.fields.secondary_email"
              outlined
              :error="
                contactDetailsForm.errors.hasOwnProperty('secondary_email')
              "
              :error-messages="contactDetailsForm.errors['secondary_email']"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="contactDetailsForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="contactDetailsForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="emergencyContactsForm.dialog" max-width="1000px">
      <v-card>
        <v-form @submit.prevent="saveEmergencyContacts" method="post">
          <v-card-title>
            <span class="headline">Edit Emergency Contacts</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-card outlined>
                  <v-card-subtitle>Emergency Contact 1</v-card-subtitle>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-text-field
                      label="Name"
                      type="text"
                      v-model="emergencyContactsForm.fields.emergency_1_name"
                      outlined
                      :error="
                        emergencyContactsForm.errors.hasOwnProperty(
                          'emergency_1_name'
                        )
                      "
                      :error-messages="
                        emergencyContactsForm.errors['emergency_1_name']
                      "
                    ></v-text-field>
                    <v-text-field
                      label="Mobile Phone Number"
                      type="tel"
                      v-model="
                        emergencyContactsForm.fields.emergency_1_mobile_phone
                      "
                      outlined
                      :error="
                        emergencyContactsForm.errors.hasOwnProperty(
                          'emergency_1_mobile_phone'
                        )
                      "
                      :error-messages="
                        emergencyContactsForm.errors['emergency_1_mobile_phone']
                      "
                    ></v-text-field>
                    <v-text-field
                      label="Home Phone Number"
                      type="tel"
                      v-model="
                        emergencyContactsForm.fields.emergency_1_home_phone
                      "
                      outlined
                      :error="
                        emergencyContactsForm.errors.hasOwnProperty(
                          'emergency_1_home_phone'
                        )
                      "
                      :error-messages="
                        emergencyContactsForm.errors['emergency_1_home_phone']
                      "
                    ></v-text-field>
                    <v-textarea
                      label="Address"
                      v-model="emergencyContactsForm.fields.emergency_1_address"
                      outlined
                      rows="5"
                      :error="
                        emergencyContactsForm.errors.hasOwnProperty(
                          'emergency_1_address'
                        )
                      "
                      :error-messages="
                        emergencyContactsForm.errors['emergency_1_address']
                      "
                    ></v-textarea>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card outlined>
                  <v-card-subtitle>Emergency Contact 2</v-card-subtitle>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-text-field
                      label="Name"
                      type="text"
                      v-model="emergencyContactsForm.fields.emergency_2_name"
                      outlined
                      :error="
                        emergencyContactsForm.errors.hasOwnProperty(
                          'emergency_2_name'
                        )
                      "
                      :error-messages="
                        emergencyContactsForm.errors['emergency_2_name']
                      "
                    ></v-text-field>
                    <v-text-field
                      label="Mobile Phone Number"
                      type="tel"
                      v-model="
                        emergencyContactsForm.fields.emergency_2_mobile_phone
                      "
                      outlined
                      :error="
                        emergencyContactsForm.errors.hasOwnProperty(
                          'emergency_2_mobile_phone'
                        )
                      "
                      :error-messages="
                        emergencyContactsForm.errors['emergency_2_mobile_phone']
                      "
                    ></v-text-field>
                    <v-text-field
                      label="Home Phone Number"
                      type="tel"
                      v-model="
                        emergencyContactsForm.fields.emergency_2_home_phone
                      "
                      outlined
                      :error="
                        emergencyContactsForm.errors.hasOwnProperty(
                          'emergency_2_home_phone'
                        )
                      "
                      :error-messages="
                        emergencyContactsForm.errors['emergency_2_home_phone']
                      "
                    ></v-text-field>
                    <v-textarea
                      label="Address"
                      v-model="emergencyContactsForm.fields.emergency_2_address"
                      outlined
                      rows="5"
                      :error="
                        emergencyContactsForm.errors.hasOwnProperty(
                          'emergency_2_address'
                        )
                      "
                      :error-messages="
                        emergencyContactsForm.errors['emergency_2_address']
                      "
                    ></v-textarea>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="emergencyContactsForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="emergencyContactsForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    teamMember: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      contactDetailsForm: {
        dialog: false,
        fields: {
          mobile_phone: null,
          home_phone: null,
          address: null,
          secondary_email: null,
        },
        loading: false,
        errors: {},
      },
      emergencyContactsForm: {
        dialog: false,
        fields: {
          emergency_1_name: null,
          emergency_1_mobile_phone: null,
          emergency_1_home_phone: null,
          emergency_1_address: null,
          emergency_2_name: null,
          emergency_2_mobile_phone: null,
          emergency_2_home_phone: null,
          emergency_2_address: null,
        },
        loading: false,
        errors: {},
      },
    };
  },

  mounted() {
    if (this.teamMember.contact_details) {
      if (this.teamMember.contact_details.mobile_phone) {
        this.contactDetailsForm.fields.mobile_phone =
          this.teamMember.contact_details.mobile_phone;
      }
      if (this.teamMember.contact_details.home_phone) {
        this.contactDetailsForm.fields.home_phone =
          this.teamMember.contact_details.home_phone;
      }
      if (this.teamMember.contact_details.address) {
        this.contactDetailsForm.fields.address =
          this.teamMember.contact_details.address;
      }
      if (this.teamMember.contact_details.secondary_email) {
        this.contactDetailsForm.fields.secondary_email =
          this.teamMember.contact_details.secondary_email;
      }
      if (this.teamMember.contact_details.emergency_1_name) {
        this.emergencyContactsForm.fields.emergency_1_name =
          this.teamMember.contact_details.emergency_1_name;
      }
      if (this.teamMember.contact_details.emergency_1_mobile_phone) {
        this.emergencyContactsForm.fields.emergency_1_mobile_phone =
          this.teamMember.contact_details.emergency_1_mobile_phone;
      }
      if (this.teamMember.contact_details.emergency_1_home_phone) {
        this.emergencyContactsForm.fields.emergency_1_home_phone =
          this.teamMember.contact_details.emergency_1_home_phone;
      }
      if (this.teamMember.contact_details.emergency_1_address) {
        this.emergencyContactsForm.fields.emergency_1_address =
          this.teamMember.contact_details.emergency_1_address;
      }
      if (this.teamMember.contact_details.emergency_2_name) {
        this.emergencyContactsForm.fields.emergency_2_name =
          this.teamMember.contact_details.emergency_2_name;
      }
      if (this.teamMember.contact_details.emergency_2_mobile_phone) {
        this.emergencyContactsForm.fields.emergency_2_mobile_phone =
          this.teamMember.contact_details.emergency_2_mobile_phone;
      }
      if (this.teamMember.contact_details.emergency_2_home_phone) {
        this.emergencyContactsForm.fields.emergency_2_home_phone =
          this.teamMember.contact_details.emergency_2_home_phone;
      }
      if (this.teamMember.contact_details.emergency_2_address) {
        this.emergencyContactsForm.fields.emergency_2_address =
          this.teamMember.contact_details.emergency_2_address;
      }
    }
  },

  methods: {
    openContactDetails() {
      this.contactDetailsForm.dialog = true;
    },
    openEmergencyContacts() {
      this.emergencyContactsForm.dialog = true;
      this.emergencyContactsForm.errors = {};
    },
    saveContactInformation() {
      const appId = this.$route.params.id;
      const teamMemberId = parseInt(this.teamMember.id);
      this.contactDetailsForm.loading = true;
      this.contactDetailsForm.errors = {};

      this.$store
        .dispatch("team/updateContactInformation", {
          appId,
          teamMemberId,
          fields: this.contactDetailsForm.fields,
        })
        .then(() => {
          this.contactDetailsForm.loading = false;
          this.contactDetailsForm.dialog = false;
        })
        .catch((errors) => {
          this.contactDetailsForm.errors = errors.response.data.errors;
          this.contactDetailsForm.loading = false;
        });
    },
    saveEmergencyContacts() {
      const appId = this.$route.params.id;
      const teamMemberId = parseInt(this.teamMember.id);
      this.emergencyContactsForm.loading = true;
      this.emergencyContactsForm.errors = {};

      this.$store
        .dispatch("team/updateEmergencyContacts", {
          appId,
          teamMemberId,
          fields: this.emergencyContactsForm.fields,
        })
        .then(() => {
          this.emergencyContactsForm.loading = false;
          this.emergencyContactsForm.dialog = false;
        })
        .catch((errors) => {
          this.emergencyContactsForm.errors = errors.response.data.errors;
          this.emergencyContactsForm.loading = false;
        });
    },
  },
};
</script>
